import { Link } from "gatsby";
import React from "react";
import "../components/Header/Header.scss";
import Layout from "../components/layout";

const NotFoundPage = (props) => {

  return (
    <Layout>
      <section className="thank-you text-center mt-80">
        <div className="flex flex-wrap">
          <div className="lg:w-7/12 w-full closemenu">
            <div
              className="xl:px-80 lg:px-40
             px-20 lg:py-40 pb-20 pt-20 relative h-screen lgscreen:max-h-[380px] overflow-y-auto enquiry-left"
            >
              <div className="enquiry-header mb-50">
              </div>
              <div className="scroll-form-main thankYou-content flex flex-col justify-center items-start gap-y-6">
              <div className="title-black mb-0"><span className="!mb-0">404 Error</span></div>
                <div className="top-info flex justify-center flex-wrap items-center">
                  <h1 className="text-black-500 lg:text-34">Whoops hang in there!</h1>
                </div>
                <div>
                <p className="text-left">
                We're sorry the page you're looking for doesn't exist. <br></br> 
                Let's help guide you back on your adventure.
                </p>
                </div>
                <Link to="/" className="link-btn">Explore Home</Link>
              </div>
            </div>
          </div>
          <div className="lg:w-5/12 w-full">
            <div className="img h-80 lgscreen:h-[320px]">
              <img
                className="w-full h-screen lgscreen:h-[350px] object-cover"
                src="/images/404Page.webp"
                alt=""
              ></img>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
